import React from 'react';
import { Container, Box, Flex } from 'theme-ui';
import { FooterLogo } from './Footer.Logo';
import { FooterMenu } from './Footer.Menu';
import { FooterSocial } from './Footer.Social';

const styles = {
  wrapper: {
    position: 'relative',
    bg: 'footerBg',
    // mt: [5, 6],
    backgroundImage: 'linear-gradient(90deg, rgba(90,82,70,0.7) 0%, rgba(23,24,40,0.7) 100%)',
    backgroundAttachment: 'fixed',
  },
};

export const Footer = () => (
  <Box sx={styles.wrapper}>
    <Container variant='compact' sx={styles.container}>
      <Flex variant='layout.footer'>
        <Box>
          <FooterLogo />
        </Box>
        <FooterMenu />
        <Box>
          <FooterSocial />
        </Box>
      </Flex>
    </Container>
  </Box>
);
