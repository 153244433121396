const white = '#fff'
const gold = {
  50: '#f5d4b5',
  100: '#dbbea2',
  200: '#c4aa91',
  300: '#f1e3b0',
  400: '#ad9680',
  500: '#94806d',
  600: '#9c8773',
  700: '#877564',
  800: '#736355',
  900: '#5e5146'
}
const gray = {
  200: '#edf2f7',
  300: '#e2e8f0',
  400: '#cbd5e0',
  500: '#a0aec0',
  600: '#718096',
  700: '#4a5568',
  800: '#2d3748',
  900: '#1a202c'
}
const indigo = {
  100: '#ebf4ff',
  300: '#a3bffa',
  500: '#667eea',
  600: '#5a67d8',
  800: '#434190'
}
const purple = {
  100: '#faf5ff',
  300: '#d6bcfa',
  500: '#9f7aea',
  600: '#805ad5',
  800: '#553c9a'
}
const green = {
  100: '#f0fff4',
  500: '#48bb78'
}
const red = {
  100: '#fff5f5',
  500: '#f56565'
}

//Primary (replace indigo by Gold)
const alpha = {
  alphaLighter: gold[100],
  alphaLight: gold[300],
  alpha: gold[300],
  alphaDark: gold[600],
  alphaDarker: gold[800]
}

// Secondary
const beta = {
  betaLighter: purple[100],
  betaLight: purple[300],
  beta: purple[500],
  betaDark: purple[600],
  betaDarker: purple[800]
}

// Reserved
const gamma = {}

// Reserved
const psi = {}

// Neutral
const omega = {
  omegaLighter: gray[200],
  omegaLight: gray[300],
  omega: gray[500],
  omegaDark: gray[600],
  omegaDarker: gray[800]
}

export default {
  /* text: gray[600],
  article: gray[700],
  heading: gray[800], */
  text: gray[400],
  article: gray[300],
  heading: gray[200],

  ...alpha,
  ...beta,
  ...gamma,
  ...psi,
  ...omega,

  successLight: green[100],
  success: green[500],
  errorLight: red[100],
  error: red[500],

  white: white,
  // background: gray[300],
  contentBg: white,
  headerBg: `transparent`,
  footerBg: `transparent`, // slm - previous: gray[300],

  mute: gray[300],
  highlight: gray[200],

  modes: {
    dark: {
      // Reverse alpha
      alphaLighter: alpha.alphaDarker,
      alphaLight: alpha.alphaDark,
      alphaDark: alpha.alphaLight,
      alphaDarker: alpha.alphaLighter,

      // Reverse beta
      betaLighter: beta.betaDarker,
      betaLight: beta.betaDark,
      betaDark: beta.betaLight,
      betaDarker: beta.betaLighter,

      // Reverse omega
      omegaLighter: omega.omegaDarker,
      omegaLight: omega.omegaDark,
      omegaDark: omega.omegaLight,
      omegaDarker: omega.omegaLighter,

      text: gray[400],
      article: gray[300],
      heading: gray[200],

      // background: `#151718`,  - SLM - Defined in layout.js -  // SLM Update #151718  2B313D
      contentBg: gray[700],
      headerBg: `transparent`,
      footerBg: `transparent`, // previous: `#151718`, SLM - Defined in layout.js -  // SLM Update #27272A

      mute: gray[600]
    }
  }
}
